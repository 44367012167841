import React, { useState } from 'react';
import './App.css'; // Remove this if not used
import Chat from './Chat';

function App() {
  return (
    <div>
      <div className="App w-screen min-h-screen flex flex-col justify-center items-center bg-gray-100">
        <Chat />
      </div>
    </div>
  );
}

export default App;
