import React, { useState, useEffect, useRef } from 'react';

// Import React and other necessary modules

function Chat() {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [sources, setSources] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const chatHistoryRef = useRef(null);

  useEffect(() => {
    fetchNewSession();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  const fetchNewSession = async () => {
    try {
      const response = await fetch('https://aiapi.birgirisim.com.tr/new-session/');
      if (!response.ok) {
        throw new Error('Failed to fetch new session');
      }
      const data = await response.json();
      setSessionId(data.session_id);
      fetchChatHistory(data.session_id);
    } catch (error) {
      console.error('Error fetching new session:', error);
    }
  };

  const fetchChatHistory = async (session_id) => {
    try {
      const response = await fetch(`https://aiapi.birgirisim.com.tr/chat-history/${session_id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch chat history');
      }
      const data = await response.json();
      setChatHistory(data);
    } catch (error) {
      console.error('Error fetching chat history:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch('https://aiapi.birgirisim.com.tr/get-answer/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ session_id: sessionId, question }),
      });
      if (!response.ok) {
        throw new Error('Failed to get answer');
      }
      setQuestion('');
      const data = await response.json();
      console.log(data);
      setAnswer(data.answer);
      setSources(data.sources);
      setChatHistory([...chatHistory, { question, answer: data.answer }]);

    } catch (error) {
      console.error('Error getting answer:', error);
    }
    setLoading(false);
  };

  const scrollToBottom = () => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  };

  return (
    <div className="flex flex-col md:flex-row justify-between h-screen bg-gray-800 p-2 mx-0 w-screen">
      {/* Source section */}
      <div className='flex flex-col h-2/5 md:h-full md:mr-2 w-full md:w-2/6 bg-gray-500/60 mb-2 sm:rounded-t md:rounded-l'>
        <h1 className='text-gray-100 text-end font-bold bg-orange-800 pr-2'>Kaynaklar</h1>
        <div className='flex md:flex-col bg-gray-500 flex-grow'>
          <div className='flex flex-col bg-orange-800 w-2/6 md:h-2/6 md:w-full'>
            {/* Books list */}
            <h1 className='text-white font-bold'>Kitaplar:</h1>
            <div className="flex justify-center items-center bg-gray-800 m-2 h-12 rounded cursor-pointer">
              <h2 className="text-md text-gray-100">1. Namaz Kitabı</h2>
            </div>
          </div>
          <div className='w-4/6 md:h-full md:w-full max-h-[300px] md:max-h-[400px]'>
            {/* Text reference */}
            <h1 className='text-white text-center ml-2 mb-3 font-bold'>Cavap Kaynakları:</h1>

            <div className='max-h-[300px] md:max-h-[400px] overflow-y-auto'>
              {sources.map((source, index) => (
                <div key={index} className="text-white px-2">
                  <div className='bg-gray-800 rounded-lg p-2 px-3 text-start text-justify mb-10'>
                    <div className='text-orange-700 mb-3 text-center'>
                      <span>Kaynak {index + 1}: </span>{source.metadata.source}
                    </div>
                    <div>
                      {source.page_content}
                    </div>

                  </div>

                </div>
              ))}
            </div>

          </div>
        </div>
      </div>

      {/* Chat section */}
      <div className='h-3/5 bg-gray-950 rounded-b-xl md:w-4/6 md:h-full overflow-hidden'>
        {/* Chat history */}
        <div id="chat-history" ref={chatHistoryRef} className='h-5/6 overflow-y-auto p-2'>
          {chatHistory.map((message, index) => (
            <div key={index} className={`mb-12`}>
              <p className="text-gray-300 mb-8">
                <strong className='text-start text-orange-700'>Soru:</strong> {message.question}
              </p>

              <p className="text-gray-300">
                <div className='flex'>
                  <span>
                    <span class="flex bg-gray-300 p-2 bg-gray-500 rounded-full items-center justify-center overflow-hidden w-12 h-12 flex-shrink-0">
                      <img src="logo-yazi.png" alt="Avatar" class="w-5/6 h-5/6" />
                    </span>
                  </span>
                  <div className='text-start text-justify px-3'>
                    {message.answer}
                  </div>

                </div>
              </p>
            </div>
          ))}
        </div>

        {/* User query input */}
        <div className='h-full w-full rounded-xl font-bold px-4'>
          <form onSubmit={handleSubmit} className='bg-gray-600 p-2 rounded-b-xl h-1/6 flex items-center'>
            <div className="items-center w-9/12 h-full">
              <input
                type='text'
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                className='h-full w-full rounded-xl text-x text-orange-700 font-bold focus:outline-none pl-3'
                placeholder='Soru sor...'
              />
              
            </div>
            <div className='w-1/12 pl-2'>
              {loading && (
                  <div className="right-12 flex items-center justify-center">
                    <svg className="w-9 h-9 animate-spin text-gray-900/50" viewBox="0 0 64 64" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                        stroke="currentColor" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"></path>
                      <path
                        d="M32 3C36.5008 3 40.9338 3.8854 45.0195 5.60153C49.1052 7.31766 52.7557 9.82308 55.6568 12.9393"
                        stroke="#f97316" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                  </div>
              )}
            </div>
            <button type='submit' className='ml-2 bg-orange-700 text-gray-200 p-2 rounded-xl'>Gönder</button>
          </form>
        </div>

      </div>
    </div>
  );
}

export default Chat;
